import React from 'react'
import styles from './SOBanner.module.scss'

// export default function SOBanner() {
//   return (
//     <section className={styles.container}>
//       <a href="https://guitareo.sjv.io/c/1289539/1276019/14650" target="_blank" rel="noopener noreferrer">
//         <div className={styles.banner}>
//           <div className={styles.coolButton}>Get Started</div>
//         </div>
//         {/* <img src='so/so-logo.png' className={styles.soLogo} loading='lazy' height="48px" width="274px" />
//                     <img src='so/text-instant-hits.png' className={styles.textInstantHits} loading='lazy' height="230px" width="297px" />
//                     <img src='so/cta-button.png' className={styles.ctaButton} loading='lazy' heigh="81px" width="251px" />
//                     <img src='so/pack-3d-min.png' className={styles.ultimateMidiPack} loading='lazy' height="418px" width="274px" /> */}

//       </a>
//     </section>
//   )
// }


export default function SOBanner(props) {
  const { instrument }  = props
  return (
    <section className={styles.container}>
      <a href={`https://quiz.guitar-tuner.org?type=${instrument ? instrument : 'acoustic-guitar'}`} target="_blank" rel="noopener noreferrer">
        <div className={styles.banner}>
          <div className={styles.coolButton}>Take Quiz</div>
        </div>
        {/* <img src='so/so-logo.png' className={styles.soLogo} loading='lazy' height="48px" width="274px" />
                    <img src='so/text-instant-hits.png' className={styles.textInstantHits} loading='lazy' height="230px" width="297px" />
                    <img src='so/cta-button.png' className={styles.ctaButton} loading='lazy' heigh="81px" width="251px" />
                    <img src='so/pack-3d-min.png' className={styles.ultimateMidiPack} loading='lazy' height="418px" width="274px" /> */}

      </a>
    </section>
  )
}
