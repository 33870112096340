import ShadowCard from '../components/ShadowCard'
import Tuner from '../components/Tuner'
import TunerHome from '../components/TunerHome'
import styles from '../components/Page.module.scss'
import useTranslation from '../translations/useTranslation'
import AffiliateLinkBanner from './AffiliateLinkBanner'
import SOBanner from './SOBanner'

const SHOW_SO_BANNER = process.env.NEXT_PUBLIC_SHOW_SO_BANNER === 'true'

function Page(props) {
  const { instrumentNames } = props
  const { t } = useTranslation()

  return (
    <>
      <ShadowCard>
        {props.instrument ? <Tuner instrument={props.instrument} /> : <TunerHome />}
      </ShadowCard>

      {props.instrument && (SHOW_SO_BANNER && <SOBanner instrument={props.instrument} /> || <AffiliateLinkBanner />) }

      <h2 className={styles.heading}>{t('howToUseHeader')}</h2>

      <ShadowCard>
        <div className={styles.textContainer}>
          {t('howToUseText', { instrumentNames, t })}
        </div>
      </ShadowCard>
    </>
  )
}

export default Page
